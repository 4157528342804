<script>
	import { onMount } from 'svelte';
	let livechatcontainer;
	function loadLc(nointeraction) {
		clearTimeout(window.chattimeout);
		window.__lc = window.__lc || {};
		window.__lc.license = 9763720;

		window.LC_API = window.LC_API || {};

		window.LC_API.on_after_load = function () {
			livechatcontainer[0].style.display = 'none';
			if (nointeraction != true) {
				window.LC_API.open_chat_window();
			}
		};

		var lc = document.createElement('script');
		lc.type = 'text/javascript';
		lc.async = true;
		lc.src =
			('https:' == document.location.protocol ? 'https://' : 'http://') +
			'cdn.livechatinc.com/tracking.js';
		var s = document.getElementsByTagName('script')[0];
		s.parentNode.insertBefore(lc, s);
	}

	onMount(async () => {
		livechatcontainer = document.querySelectorAll('.lccontainer');

		window.chattimeout = setTimeout(function () {
			loadLc(true);
		}, 180000);
	});
</script>

<div
	class="lccontainer"
	role="button"
	tabindex="0"
	onclick={loadLc}
	onkeydown={loadLc}
>
	<svg class="lcicon" color="var(--indow-blue)" viewBox="0 0 32 32"
		><path
			fill="currentColor"
			d="M11.9,26H8.6c-3.3,0-6.2-2.4-6.4-5.8C1.9,17,2,12,2.2,8.9c0.3-3.1,2.8-5.4,5.8-5.6c4.8-0.3,11-0.3,15.9,0 c3.1,0.2,5.6,2.6,5.8,5.7c0.2,3.1,0.2,8.2,0,11.2c-0.3,3.4-3.2,5.8-6.4,5.8h-2.5c-0.4,0-0.9,0.2-1.2,0.4l-6.1,4.4 C12.9,31.3,12,30.9,12,30v-4H11.9z"
		/></svg
	>
	<svg class="lctyping" viewBox="0 0 60 40" width="18px" fill="white"
		><circle r="6" cy="20" cx="9" class="lctypingdot lctypingdot1" /><circle
			r="6"
			cy="20"
			cx="30"
			class="lctypingdot lctypingdot2"
		/><circle r="6" cy="20" cx="51" class="lctypingdot lctypingdot3" /></svg
	>
</div>
<noscript
	><style>
		.lccontainer {
			display: none;
		}
	</style></noscript
>

<style>
	.lcicon,
	.lctyping {
		transition: transform 200ms cubic-bezier(0.18, 0.89, 0.32, 1.28) 50ms;
	}

	.lccontainer {
		z-index: 998;
		cursor: pointer;
		background: white;
		position: fixed;
		right: 0;
		bottom: 0;
		width: 3rem;
		height: 3rem;
		border-radius: 3rem;
		margin: 0.75rem;
		padding: 0.75rem;
		box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 8px;
	}

	.lccontainer:hover .lcicon {
		transform: scale(1.25);
	}

	.lctyping {
		width: 1.5rem;
		position: absolute;
		top: 33%;
		left: 28%;
		pointer-events: none;
		transition: opacity 150ms ease-in-out 50ms;
		opacity: 0;
	}

	.lctypingdot {
		animation-duration: 1s;
		animation-iteration-count: infinite;
		animation-timing-function: cubic-bezier(0, 0, 1, 1);
	}

	.lctypingdot1 {
		animation-name: typing1;
	}

	.lctypingdot2 {
		animation-name: typing2;
	}

	.lctypingdot3 {
		animation-name: typing3;
	}

	.lccontainer:hover .lctyping {
		opacity: 1;
	}

	@keyframes typing1 {
		0%,
		66.67%,
		100% {
			transform: translate(0px, 0px);
		}
		6.67% {
			transform: translate(0px, -8px);
		}
		13.33%,
		20% {
			transform: translate(0px, -10px);
		}
		26.67% {
			transform: translate(0px, -6px);
		}
		33.33% {
			transform: translate(0px, -2px);
		}
		40% {
			transform: translate(0px, 3px);
		}
		46.67% {
			transform: translate(0px, 2px);
		}
		53.33% {
			transform: translate(0px, -1px);
		}
	}

	@keyframes typing2 {
		0%,
		6.67%,
		73.33%,
		100% {
			transform: translate(0px, 0px);
		}
		13.33% {
			transform: translate(0px, -8px);
		}
		20%,
		26.67% {
			transform: translate(0px, -10px);
		}
		33.33% {
			transform: translate(0px, -6px);
		}
		40% {
			transform: translate(0px, -2px);
		}
		46.67% {
			transform: translate(0px, 3px);
		}
		53.33% {
			transform: translate(0px, 2px);
		}
		60% {
			transform: translate(0px, -1px);
		}
	}

	@keyframes typing3 {
		0%,
		13.33%,
		80%,
		100% {
			transform: translate(0px, 0px);
		}
		20% {
			transform: translate(0px, -8px);
		}
		26.67%,
		33.33% {
			transform: translate(0px, -10px);
		}
		40% {
			transform: translate(0px, -6px);
		}
		46.67% {
			transform: translate(0px, -2px);
		}
		53.33% {
			transform: translate(0px, 3px);
		}
		60% {
			transform: translate(0px, 2px);
		}
		66.67% {
			transform: translate(0px, -1px);
		}
	}
</style>
